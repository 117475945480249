import _implementation from "./implementation";
import _defineProperties from "define-properties";
var exports = {};
var implementation = _implementation;
var supportsDescriptors = _defineProperties.supportsDescriptors;
var $gOPD = Object.getOwnPropertyDescriptor;
var $TypeError = TypeError;

exports = function getPolyfill() {
  if (!supportsDescriptors) {
    throw new $TypeError("RegExp.prototype.flags requires a true ES5 environment that supports property descriptors");
  }

  if (/a/mig.flags === "gim") {
    var descriptor = $gOPD(RegExp.prototype, "flags");

    if (descriptor && typeof descriptor.get === "function" && typeof /a/.dotAll === "boolean") {
      return descriptor.get;
    }
  }

  return implementation;
};

export default exports;