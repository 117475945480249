import _defineProperties from "define-properties";
import _polyfill from "./polyfill";
var exports = {};
var supportsDescriptors = _defineProperties.supportsDescriptors;
var getPolyfill = _polyfill;
var gOPD = Object.getOwnPropertyDescriptor;
var defineProperty = Object.defineProperty;
var TypeErr = TypeError;
var getProto = Object.getPrototypeOf;
var regex = /a/;

exports = function shimFlags() {
  if (!supportsDescriptors || !getProto) {
    throw new TypeErr("RegExp.prototype.flags requires a true ES5 environment that supports property descriptors");
  }

  var polyfill = getPolyfill();
  var proto = getProto(regex);
  var descriptor = gOPD(proto, "flags");

  if (!descriptor || descriptor.get !== polyfill) {
    defineProperty(proto, "flags", {
      configurable: true,
      enumerable: false,
      get: polyfill
    });
  }

  return polyfill;
};

export default exports;