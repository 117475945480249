var exports = {};
var $Object = Object;
var $TypeError = TypeError;

exports = function flags() {
  if (this != null && this !== $Object(this)) {
    throw new $TypeError("RegExp.prototype.flags getter called on non-object");
  }

  var result = "";

  if (this.global) {
    result += "g";
  }

  if (this.ignoreCase) {
    result += "i";
  }

  if (this.multiline) {
    result += "m";
  }

  if (this.dotAll) {
    result += "s";
  }

  if (this.unicode) {
    result += "u";
  }

  if (this.sticky) {
    result += "y";
  }

  return result;
};

export default exports;